import React from "react"
import Layout from "../components/Layout/layout"
import Service from "../components/Service/service"
import Values from "../components/Service/values"

const SecondPage = () => (
  <Layout slug = 'Service'>
    <Values id="leistungsversprechen"/>
    <Service/>
  </Layout>
)

export default SecondPage
