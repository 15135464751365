import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Expertise = () => (
  <StaticQuery
    query={graphql`
      query {
        leistungHeader: contentfulTcStatic(contentful_id: {eq: "6YOq2nm2L0bFRyC5VIfkjB"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }

        leistung: contentfulTcStatic(contentful_id: {eq: "2qf6SFDHLqV1IiRcq5SqHA"}) {
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }

        zusatzHeader: contentfulTcStatic(contentful_id: {eq: "7EkCf072knm8OAGyo66tpV"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }

        zusatz: contentfulTcStatic(contentful_id: {eq: "2iZtCC1RQKSdgfJUijPWSq"}) {
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
    }
    render = {data => (

      <div class="content" div="beratungsleistungen">
        <br/>

        <div class="columns">
          <div class="column">
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.leistungHeader.titleString}</h1>
            <h2 class="subtitle is-5 has-text-black has-text-weight-light">
              <div dangerouslySetInnerHTML={{ __html: data.leistungHeader.textContent.childMarkdownRemark.html}}/>
            </h2>

            <div dangerouslySetInnerHTML={{ __html: data.leistung.textContent.childMarkdownRemark.html}}/>
            <br/>
          </div>

          <div class="column">
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.zusatzHeader.titleString}</h1>
            <h2 class="subtitle is-5 has-text-black has-text-weight-light">
              <div dangerouslySetInnerHTML={{ __html: data.zusatzHeader.textContent.childMarkdownRemark.html}}/>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: data.zusatz.textContent.childMarkdownRemark.html}}/>
          </div>
        </div>
        <br/>
      </div>

    )}
    />
    )

export default Expertise
