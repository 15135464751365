import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Cores = () => (
  <StaticQuery
    query={graphql`
      query {
        personal: contentfulTcStatic(contentful_id: {eq: "3x1VXTrOxNO5ITkY0u53vk"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }

        individual: contentfulTcStatic(contentful_id: {eq: "31sbz5xRkPsHNM7jHcjawd"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }

        comfort: contentfulTcStatic(contentful_id: {eq: "3IygoPwMaBOdmL6pWjCf5J"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
    }
    render = {data => (

      <div class="content has-text-centered">

        <br/>

        <div class="columns">
          <div class="column">
            <span class="icon has-text-primary is-large fa-5x">
              <i class="fas fa-comments"></i>
            </span>
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.personal.titleString}</h1>
            <h2 class="subtitle is-5 has-text-grey has-text-weight-light">
              <div dangerouslySetInnerHTML={{ __html: data.personal.textContent.childMarkdownRemark.html}}/>
            </h2>
            <br/>
          </div>

          <div class="column">
            <span class="icon has-text-primary is-large fa-5x">
              <i class="fas fa-globe"></i>
            </span>
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.individual.titleString}</h1>
            <h2 class="subtitle is-5 has-text-grey has-text-weight-light">
              <div dangerouslySetInnerHTML={{ __html: data.individual.textContent.childMarkdownRemark.html}}/>
            </h2>
            <br/>
          </div>

          <div class="column">
            <span class="icon has-text-primary is-large fa-5x">
              <i class="fas fa-spa"></i>
            </span>
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.comfort.titleString}</h1>
            <h2 class="subtitle is-5 has-text-grey has-text-weight-light">
              <div dangerouslySetInnerHTML={{ __html: data.comfort.textContent.childMarkdownRemark.html}}/>
            </h2>
            <br/>
          </div>
      </div>

      </div>

    )}
    />
    )

export default Cores
